<template>
  <div class="page">
    <div class="header">
      <div>
        <span class="header-left">系统指派</span>
        <span class="header-middle">根据内容针对性指派咨询</span>
      </div>
      <div class="header-right"><span>￥</span><span>{{price}}</span><span>/次</span></div>
    </div>
    <div class="footer">
      <div class="detail">
        <div class="detail-title">
          <span>对话回合</span><span>有效时长内咨询回复次数</span>
        </div>
        <span class="detail-num">20次</span>
      </div>
      <div class="detail">
        <div class="detail-title">
          <span>对话时长</span><span>有效时长</span>
        </div>
        <span class="detail-num">24小时</span>
      </div>
      <div class="pay-money">
        <span>共需支付</span>
        <div><span>￥</span><span>{{price}}</span></div>
      </div>
    </div>
    <div class="button">
      <button type="button" name="button" @click="toPay">去支付</button>
    </div>
  </div>
</template>
<script>
let self = null;
import { Toast } from 'vant';
import { isWeiXin } from '@/utils/util';
export default {
  data() {
    return {
      price: this.$store.state.fastPrice.toFixed(2),
      getDocType: false,  // true是药极客 false春雨
      timer: null
    }
  },
  created() {
    self = this;
    // this.createDevOrder();
    this.getDocTakeOrder();
    this.heightPrice();

  },
  mounted() {
    if (this.$route.query.orderId) {
      this.$nextTick(() => {
        this.getOrderInfo(this.$route.query.orderId)
      })
    }
  },
  // beforeDestroy() {
  //   clearInterval(this.timer);
  // },
  methods: {
    heightPrice() {
      // this.$store.dispatch('setPrice');
      if(this.$store.state.visitsType !== 4) {
        this.$store.dispatch('setPrice');
      }
    },
    // 查询医生接单配置
    getDocTakeOrder() {
      this.$http('get', '/server/doctorCommissionConf/getDoctorConfig', {}, 1).then(res => {
        // console.log('查询医生接单配置=>', res);
        // if (res.data.data) {
        this.getDocType = res.data.data;
        // }
      })
    },
    // 获取第三方创建订单参数
    getCreatedOrderParams(orderId) {
      // let docDetail = self.$store.state.docDetail;
      let message = self.$store.state.message;
      let img = self.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let relation = self.$store.state.relation;
      let content = [
        { type: 'text', text: message },
        { type: 'patient_meta', age: relation.age, sex: relation.sex }
      ]
      if (img) {
        content.push({ type: 'image', file: img.join(',') })
      }
      let params = {
        // doctor_ids: docDetail.id,
        content: JSON.stringify(content),
        partner_order_id: orderId,
        pay_type: 'qc_hospital_common', //二甲医院医生
      }
      return params;
    },
    createDevOrder() {
      // let self = this;
      let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || ''
      let params = {
        memberId: userData.memberId,
        consultType: 1,
        templateType: 1,
        doctorBelong: this.getDocType ? 1 : 2, // 1药极客 2春雨
        orderPrice: this.price,
        doctorInfo: null,
        interrogationId: this.$store.state.interrogationId,
        questionContent: message,
        questionImage: img && img.join(',')
      }
      // 新增活动添加字段逻辑
      if(this.$store.state.isActivity) {
        params.isActivity = this.$store.state.isActivity
        params.activityId = this.$store.state.activityId
      }
      this.$http('post', '/server/order/addOrder', params, 1).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          // // console.log(wx.miniProgram.getEnv);
          if (isWeiXin()) {
            wx.miniProgram.getEnv(function (resData) {//获取当前环境
              // console.log(resData);
              if (resData.miniprogram) { // true 在微信小程序中
                // console.log('小程序支付');
                // console.log('getCreatedOrderParams================>', self.getCreatedOrderParams(res.data.data.orderId), self.getDocType);
                let url = '';
                if (self.getDocType) {
                  url = `/pages/topay?price=${self.price}&orderId=${res.data.data.orderId}`;
                } else {
                  url = `/pages/topay?price=${self.price}&orderId=${res.data.data.orderId}&thirdOrder=${JSON.stringify(self.getCreatedOrderParams(res.data.data.orderId))}&pageType=fast`;
                }
                wx.miniProgram.navigateTo({
                  url: url,//跳转回小程序的页面
                  success: function () {
                    // console.log('success');
                    // self.timer = setInterval(() => {
                    //   self.selectByOrderId(res.data.data.orderId);
                    // }, 2000)
                  },
                  fail: function () {
                    // console.log('fail');
                  }
                });
              } else { // false 在微信公众号里
                // console.log('微信浏览器支付');
                self.pay(res.data.data.orderId);
              }
            });
          } else { // H5支付
            // console.log('H5支付');
            this.$router.push('/consult');
            // return;
            let openid = localStorage.getItem('openid') || ''
            // // console.log(window.navigator.userAgent.match(/MicroMessenger/i))
            // // console.log(window.navigator.userAgent)
            if (openid) {  //公众号支付
              this.pay(res.data.data.orderId);
            } else {
              // this.pay();
              this.wxPay(res.data.data.orderId);
            }
          }
        } else {
          // console.log('res.data.msg1====================>',res.data.msg);
          Toast.fail(res.data.msg);
          setTimeout(() => {
            this.$router.replace({path:'/home'})
          }, 500);
        }
      })
    },
    // 查询订单状态
    selectByOrderId(orderId) {
      let params = {
        orderId: orderId
      }
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          // console.log('订单状态', res);
          if (res.data.data.orderStatus >= 1) {
            clearInterval(this.timer);
            if (!this.getDocType) {
              this.thirdOrder(orderId);
            } else {
              this.$store.state.message = '';
              this.$store.state.arrImg = null;
              this.$router.replace({ path: '/consult' })
            }
          } else {
            // clearInterval(this.timer);
            // // console.log('去列表页面===========================');
            this.$store.state.message = '';
            this.$store.state.arrImg = null;
            this.$router.replace({ path: '/consult' })
          }
        }
      })
    },
    // 微信支付
    wxPay(orderId) {
      let data = {
        subject: '充值',
        body: '在线医生',
        price: this.price,//0.01,
        orderNumber: orderId
      }
      this.$http('post', '/server/pay/getH5QrPay', data, 1).then(res => {
        // console.log('支付', res)
        if (res.data.code == 200) {
          // console.log(res.data.data.wapUrl)
          let a = document.createElement("a")
          a.href = res.data.data.wapUrl;
          a.click();
          this.timer = setInterval(() => {
            this.selectByOrderId(orderId);
          }, 2000)
        }
      })
    },
    // 公众号支付
    pay(orderId) {
      let _this = this;
      let openid = localStorage.getItem('openid') || '';
      let data = {
        subject: '充值',
        body: '在线医生',
        price: _this.price,//0.01,_this.price
        orderNumber: orderId,
        openid: openid,
        programType: 2
      }
      _this.$http('post', '/server/pay/getJsApiQrPay', data, 1).then(res => {
        // console.log('公众号', res)
        let gzhData = res.data.data;
        // console.log('参数', gzhData.appId)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": gzhData.appId,     //公众号名称，由商户传入
            "timeStamp": gzhData.timeStamp,         //时间戳，自1970年以来的秒数
            "nonceStr": gzhData.nonceStr, //随机串
            "package": `prepay_id=${gzhData.tradeNo}`,
            "signType": "MD5",         //微信签名方式：
            "paySign": gzhData.paySign //微信签名
          },
          function (res) {
            // console.log(res);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              if (_this.getDocType) {
                // 调用支付
                _this.$store.state.message = '';
                _this.$store.state.arrImg = null;
                _this.$router.replace({ path: '/consult' })
              } else {
                // 调支付
                _this.thirdOrder(orderId)
              }
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              _this.$store.state.message = '';
              _this.$store.state.arrImg = null;
              Toast('支付失败');
              _this.$router.replace({ path: '/consult' })
            }
          });
      })
    },
    // 查询订单信息
    getOrderInfo(orderId) {
      let params = {
        orderId: orderId
      }
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          // console.log('查询订单信息', res);
          this.price = res.data.data.orderPrice;
          this.$store.state.message = res.data.data.questionContent;
          if (res.data.data.questionImage) {
            this.$store.state.arrImg = res.data.data.questionImage.split(',');
          } else {
            this.$store.state.arrImg = null;
          }
          this.$store.state.relation = { age: res.data.data.age, sex: res.data.data.sex == 1 ? '男' : '女' }

        }
      })
    },
    toPay() {
      // this.getDocTakeOrder();
      if (this.$route.query.orderId) {
        // let self = this;
        // console.log(wx.miniProgram.getEnv);

        if (isWeiXin()) {
          wx.miniProgram.getEnv(function (resData) {//获取当前环境
            // console.log(resData);
            if (resData.miniprogram) { // true 在微信小程序中
              // console.log('小程序支付');
              // console.log('getCreatedOrderParams================>', self.getCreatedOrderParams(self.$route.query.orderId), self.getDocType);
              let url = '';
              if (self.getDocType) {
                url = `/pages/topay?price=${self.price}&orderId=${self.$route.query.orderId}`;
              } else {
                url = `/pages/topay?price=${self.price}&orderId=${self.$route.query.orderId}&thirdOrder=${JSON.stringify(self.getCreatedOrderParams(self.$route.query.orderId))}&pageType=fast`;
              }
              wx.miniProgram.navigateTo({
                url: url,//跳转回小程序的页面
                success: function () {
                  // console.log('success===============');
                  // self.timer = setInterval(() => {
                  //   // console.log('=================去列表页面=======================');
                  //   self.selectByOrderId(self.$route.query.orderId);
                  // }, 2000);
                },
                fail: function () {
                  // console.log('fail');
                }
              });
            } else { // false 在微信公众号里
              // console.log('微信浏览器支付');
              self.pay(self.$route.query.orderId);
            }
          });
        } else { // H5支付
          // console.log('H5支付');
          let openid = localStorage.getItem('openid') || ''
          // // console.log(window.navigator.userAgent.match(/MicroMessenger/i))
          // // console.log(window.navigator.userAgent)
          if (openid) {  //公众号支付
            this.pay(this.$route.query.orderId);
          } else {
            // this.pay();
            this.wxPay(this.$route.query.orderId);
          }
        }
      } else {
        this.createDevOrder();
      }
    },
    // 春雨订单
    thirdOrder(orderId) {
      // let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let relation = this.$store.state.relation;
      let content = [
        { type: 'text', text: message },
        { type: 'patient_meta', age: relation.age, sex: relation.sex }
      ]
      if (img) {
        content.push({ type: 'image', file: img.join(',') })
      }
      let params = {
        // doctor_ids: docDetail.id,
        content: JSON.stringify(content),
        partner_order_id: orderId,
        pay_type: 'qc_hospital_common', //二甲医院医生
      }
      this.$http('post', '/cooperation/server/problem/create_paid_problem/', params).then(res => {
        // console.log('春雨订单', res);
        if (res.data.error == 0) {
          let query = {
            problemId: res.data.problem_id,
            // doctorId: res.data.doctor_id,
            number: 20,
            orderId: this.orderId
          }
          this.$store.state.message = '';
          this.$store.state.arrImg = null;
          this.bindOrder(orderId, res.data.problem_id);
          this.$router.replace({ path: '/chating', query: query })
        }
      })
    },
    // 订单号与问题单号绑定接口
    bindOrder(orderId, problemId) {
      let params = {
        orderId: orderId,
        problemId: problemId
      }
      this.$http('post', '/server/problemOrder/orderAndProBind', params, 1).then(res => {
        // console.log(res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  min-height: calc(100% - 0.14rem);
  background: #f8f8f8;
  padding-top: 0.14rem;
}
.header {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 0.52rem 0.24rem;
  //   margin-top: 0.14rem;
  font-size: 0.26rem;
  align-items: center;
  .header-left {
    color: #333333;
    margin-right: 0.44rem;
  }
  .header-middle {
    color: #666666;
    margin-right: 1.62rem;
  }
  .header-right {
    color: #ffc025;
    font-size: 0.24rem;
    text:nth-child(2) {
      font-size: 0.36rem;
    }
  }
}

.footer {
  background: #ffffff;
  padding: 0.35rem 0.24rem;
  margin-top: 0.2rem;
  .detail {
    display: flex;
    justify-content: space-between;
    .detail-title {
      span:nth-child(1) {
        color: #333333;
        font-size: 0.28rem;
        margin-right: 0.82rem;
      }
      span:nth-child(2) {
        color: #666666;
        font-size: 0.24rem;
      }
    }
    .detail-num {
      color: #333333;
      font-size: 0.28rem;
    }
  }
  .detail:nth-child(1) {
    margin-bottom: 0.4rem;
  }
  .pay-money {
    display: flex;
    justify-content: space-between;
    padding-top: 0.32rem;
    margin-top: 0.3rem;
    border-top: 1px solid #f2f2f2;
    > text:nth-child(1) {
      font-size: 0.3rem;
      color: #333333;
      font-weight: bold;
    }
    view {
      color: #ffc025;
      font-size: 0.24rem;
      text:nth-child(1) {
        color: #ffc025;
      }
      text:nth-child(2) {
        font-size: 0.36rem;
      }
    }
  }
}
.button {
  margin: 4.72rem 0 1.08rem 0;
  padding: 0 0.4rem;
  width: calc(100% - 0.8rem);
  button {
    width: 100%;
    background: #42cec8;
    height: 0.72rem;
    line-height: 0.72rem;
    color: #ffffff;
    font-size: 0.32rem;
    border: 0;
    border-radius: 0.08rem;
  }
}
</style>
